<template>
  <div v-loading="loading">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/recordOmissions' }">漏检任务</el-breadcrumb-item>
        <el-breadcrumb-item>漏检计划详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" ref="ruleForm" label-width="100px">
        <div class="form-title">基本信息</div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="巡检点名称：" prop="name">{{ form.patrolName }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="位置名称：" prop="name">{{ form.facilityName }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="代号：" prop="name">{{ form.patrolNumber }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="正常数量：" prop="name">{{ form.normalCount }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="异常数量：" prop="name">{{ form.abnormalCount }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备数量：" prop="name">{{ form.patrolDeviceCount }}</el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="类型：" prop="name">
              {{ form.taskType == 0 ? '巡检':'点检' }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
          </el-col>
        </el-row>

        <div class="form-title">设备巡视信息</div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="alias" label="设备别名"></el-table-column>
          <el-table-column prop="deviceName" label="设备名称"></el-table-column>
          <el-table-column prop="model" label="设备型号"></el-table-column>
          <el-table-column prop="deviceTypeName" label="设备类型"></el-table-column>
             <el-table-column prop="deviceStatus" label="设备状态">
          <template>
            <span style="color: red">漏检</span>
          </template>
        </el-table-column>
          <el-table-column prop="staffNames" label="检查人员"></el-table-column>
          <el-table-column prop="overTime" label="完成日期"></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      row: {},
      loading: false,
      tableData: []
    };
  },
  methods: {
    details(row) {
      this.$router.push({ name: 'recordOmissionsDeviceDetail', params: {row: row, data: this.form}})
    },
    loadData() {
      this.loading = true
      this.$ajax.post("recordOmissionDetail", {
        taskId: this.row.taskId,
      }).then((res) => {
        this.form = res.data
        this.tableData = res.data.taskDeviceItems
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
			this.$router.push('/recordOmissions')
		} else {
      this.row = this.$route.params.row
    }
    this.loadData()
  },
};
</script>

<style lang="less" scoped>
.img-box {
  img {
    display: inline-block;
    width: 200px;
    height: 200px;
    margin-left: 10px;
  }
}
</style>
<style lang="less">
</style>
